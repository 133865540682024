<template>
  <div class="registered-union">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }"><i class="el-icon-s-home"></i></el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/user' }">会员中心</el-breadcrumb-item>
      <el-breadcrumb-item>推广联盟</el-breadcrumb-item>
    </el-breadcrumb>
    <div>
      <h3>推广联盟信息</h3>
      <div>
        <h4>我的联盟账号</h4>
        <el-divider></el-divider>
        <el-form label-width="120px">
          <el-form-item label="公司名称">
            <el-input placeholder="公司名称"></el-input>
          </el-form-item>
          <el-form-item label="网址">
            <el-input placeholder="网址"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div>
        <h4>支付信息</h4>
        <el-divider></el-divider>
        <el-form label-width="120px">
          <el-form-item label="税号">
            <el-input placeholder="税号"></el-input>
          </el-form-item>
          <el-form-item label="支付信息">
            <el-radio v-model="payInfo" label="1">支票</el-radio>
            <el-radio v-model="payInfo" label="2">PayPal</el-radio>
            <el-radio v-model="payInfo" label="3">银行转账</el-radio>
          </el-form-item>
          <el-form-item label="支票受益人">
            <el-input placeholder="支票受益人"></el-input>
          </el-form-item>
          <el-row type="flex" justify="end" align="center">
            <p style="margin: 0">我已阅读并同意</p>
            <a href="#">关于我们</a>
            <el-checkbox v-model="aboutUs"></el-checkbox>
            <el-button>提交</el-button>
          </el-row>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'registeredUnion',
  data () {
    return {
      payInfo: '1',
      aboutUs: false
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/scss/index";
.registered-union {
  @include body;
}
</style>
